<template>
  <Title :text="portfolioLabel" />
  <SubTitle :text="portfolioDescriptionLabel" />
  <section class="portfolio-section" v-for="(portfolio, index) in portfolios" :key="index">
    <section class="portfolio-details-section">
      <h4>{{ portfolio.title }}</h4>
    </section>

    <Paragraph class="portfolio-description" :text="portfolio.description" />
    <a class="portfolio-link" :href="portfolio.link">{{ portfolio.link }}</a>
    <br/>
    <p v-if="portfolio.image">
        <a :href="portfolio.link">
            <img class="portfolio-image" :src="require(`@/assets/images/portfolio/${portfolio.image}`)" />
        </a>
    </p>
  </section>
</template>

<script>
import Title from "@/components/Title.vue";
import SubTitle from "@/components/SubTitle.vue";
import Paragraph from "@/components/Paragraph.vue";

export default {
  Name: "Portfolio",

  components: { Title, SubTitle, Paragraph },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    portfolioLabel() {
      return this.currentLanguage.resumeLabels.portfolio;
    },

    portfolioDescriptionLabel() {
      return this.currentLanguage.resumeLabels.portfolioDescription;
    },

    portfolios() {
      return this.currentLanguage.data.portfolios;
    },
  },
};
</script>

<style scoped>
.portfolio-section {
  width: 100%;
  margin-bottom: 2em;
}

.portfolio-details-section {
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portfolio-description {
  overflow-wrap: break-word;
}

.portfolio-link {
  font-weight: bold;
}

.portfolio-image {
  max-width: 400px;
}

@media (max-width: 21cm) {
    .portfolio-image {
        max-width: 100%;
    }

    .portfolio-link {
        max-width: 245px;
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        word-break: break-word;

        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
}
</style>
