<template>
    <a v-if="link != '#'" :href="link"><p>{{ text }}</p></a>
    <div v-else>
        <div v-for="(text, index) in getText" :key="index">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
  Name: "Paragraph",

  props: {
    text: {
      type: [String, Number],
      required: true,
    },
    link: {
      type: [String, Number],
      required: false,
      default: '#',
    },
  },

  computed: {
    getText() {
      if (!this.text) return ['']
      return this.text.split('\\n');
    }
  }
};
</script>

<style scoped>
p {
  line-height: 22px;
  margin: 0;
  font-size: 15px;
  color: #303030;
}
</style>
